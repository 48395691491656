import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
} from "react-share";
import { cn } from "@util/helpers";

import { WHATSAPP_URL } from "./constants";
import { getIconSrc } from "./helpers";
import { IconName, SocialMediaType } from "./types";

export type SocialEmbedButtonFactoryProps = {
  whichSocialMedia: SocialMediaType;
  shareUrl: string;
  sizeClass: string;
  commonCss: string;
  title?: string;
};

export default function SocialEmbedButtonFactory({
  whichSocialMedia = SocialMediaType.facebook,
  shareUrl = "",
  sizeClass = "h-[36px] aspect-square",
  commonCss,
  title,
}: SocialEmbedButtonFactoryProps) {
  switch (whichSocialMedia) {
    case SocialMediaType.twitter:
      return (
        <TwitterShareButton
          url={shareUrl}
          className={cn(sizeClass, commonCss)}
          title={title}
        >
          <img
            src={getIconSrc(IconName.twitter)}
            width={24}
            height={24}
            alt="twitter"
          />
        </TwitterShareButton>
      );

    case SocialMediaType.telegram:
      return (
        <TelegramShareButton
          url={shareUrl}
          className={cn(sizeClass, commonCss)}
          title={title}
        >
          <img
            src={getIconSrc(IconName.telegram)}
            width={24}
            height={24}
            alt="telegram"
          />
        </TelegramShareButton>
      );

    case SocialMediaType.whatsapp:
      return (
        <a
          className={cn(sizeClass, commonCss)}
          href={`${WHATSAPP_URL}${encodeURIComponent(title || "")} ${encodeURIComponent(shareUrl || "")}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={getIconSrc(IconName.whatsapp)}
            width={24}
            height={24}
            alt="whatsapp"
          />
        </a>
      );

    case SocialMediaType.email:
      return (
        <EmailShareButton
          url={shareUrl}
          subject="Sharing from The Business Times"
          body={title}
          separator=" - "
          className={cn(sizeClass, commonCss)}
        >
          <img
            src={getIconSrc(IconName.email)}
            width={24}
            height={24}
            alt="email"
          />
        </EmailShareButton>
      );

    case SocialMediaType.linkedin:
      return (
        <LinkedinShareButton
          url={shareUrl}
          className={cn(sizeClass, commonCss)}
          title={title}
        >
          <img
            src={getIconSrc(IconName.linkedin)}
            width={24}
            height={24}
            alt="linkedin"
          />
        </LinkedinShareButton>
      );

    case SocialMediaType.facebook:
    default:
      return (
        <FacebookShareButton
          url={shareUrl}
          className={cn(sizeClass, commonCss)}
          title={title}
        >
          <img
            src={getIconSrc(IconName.facebook)}
            width={24}
            height={24}
            alt="facebook"
          />
        </FacebookShareButton>
      );
  }
}
