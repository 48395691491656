import { BlockTitle } from "@app/blocks/Blocks";
import type { ArticleDataObject, Media } from "@app/types/Cue";
import type { NavigationItem } from "@app/types/Navigation";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems } from "@util/constant";
import {
  getKickerFromArticleDataObject,
  isArticleUpdated,
} from "@util/helpers";
import cx from "classnames";

export type AseanProps = {
  testId?: string | null;
  data?: ArticleDataObject[];
};

const sections: NavigationItem[] = [
  sectionNavigationItems["international/asean"],
  sectionNavigationItems["international/global"],
  sectionNavigationItems["startups-tech/startups"],
  sectionNavigationItems["singapore/smes"],
] as NavigationItem[];

export default function Asean({
  testId = null,
  data = [],
}: AseanProps): React.ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const disablePaywall = OKTAUserInfo?.usertype !== OKTAUserTypeEnum.SUBSCRIBER;

  return (
    <section
      className={cx("stories md:grid md:grid-cols-2 md:gap-6 lg:grid-cols-4")}
      data-testid={testId}
    >
      {sections.map((section: NavigationItem, index: number) => {
        const article: ArticleDataObject | undefined = data[index];
        const mainSection = article?.sections?.[0];

        const variant: CardVariations = {
          image: {
            position: "",
            width: "w-120px lg:w-full",
          },
          content: {
            width: "w-full-120px lg:w-full",
            extraClass: cx("pl-4", "lg:pl-0 lg:pt-3"),
            updatedTimeColor: "",
          },
          title: {
            color: "!text-gray-850",
            size: "text-base",
          },
        };

        return article ? (
          <div
            key={index}
            className={cx(
              "story border-t border-gray-850 pb-10 pt-3 md:col-span-1 md:pb-3"
            )}
          >
            <BlockTitle
              rootClassName="!text-lg mb-2"
              rootLinkClassName=""
              text={section.label?.toUpperCase() ?? ""}
              link={section.link}
            />

            <BasicCard
              id={article.id}
              slug={article.slug}
              media={article.media as Media}
              title={article.displaySetting?.displayHeadline || article.title}
              kicker={
                article.kicker?.fields?.[0]?.value ||
                getKickerFromArticleDataObject(article)
              }
              paywall={
                !disablePaywall ? article.paywall?.contentAccess === "1" : false
              }
              defaultImage={
                mainSection
                  ? { directoryName: mainSection.uniqueName }
                  : undefined
              }
              variations={variant}
              edited={
                isArticleUpdated(article.updated, article.edited)
                  ? article.edited
                  : undefined
              }
              hasUpdatedTimestampDisplay={
                article.displaySetting?.hasUpdatedTimeDisplayed
              }
            />
          </div>
        ) : null;
      })}
    </section>
  );
}
