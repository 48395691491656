import { useState } from "react";
import { Element, Tag } from "@app/types/Cue";
import { AdvertisementTypeEnum } from "@app/types/enums";
import Advertisement from "@components/Advertisement/Advertisement";
import Container from "@components/Container/Container";
import { useWindowInnerWidth } from "@hooks/useWindowInnerWidth";
import ArticleNewsletter from "@pages/Article/components/ArticleNewsletter/ArticleNewsletter";
import { ArticleParagraphsProps } from "@pages/Article/components/ArticleParagraphs";
import ArticleReadMore from "@pages/Article/components/ArticleReadMore";
import ArticleSeeAlso from "@pages/Article/components/ArticleSeeAlso";
import { getFormattedTextForAds } from "@pages/Section/helpers";
import { cn, GoogleAdsSlotFactory } from "@util/helpers";

import Paragraph from "./Paragraph";

type PropsToImport =
  | "id"
  | "seeAlso"
  | "displayRelatedLinksAndNewsletterSignupForm"
  | "keywords"
  | "index"
  | "gsChannels"
  | "sections"
  | "readMoreArticle";

type ParagraphWrapperProps = Pick<ArticleParagraphsProps, PropsToImport> & {
  element: Element;
  paragraphCount: number;
  isBoxWrapped?: boolean;
  totalParagraphs: number;
  adsWrapperClass?: string;
  isDisplayImu1?: boolean;
  isBrandedContent?: boolean;
  fullScreenAds?: boolean;
  displayArticleReadMore?: boolean;
  displayNewVersionNewsletter?: boolean;
  elementsClass?: string;
};

export default function ParagraphWrapper({
  id,
  element,
  paragraphCount,
  index,
  sections,
  keywords,
  gsChannels = "no_analysis",
  displayRelatedLinksAndNewsletterSignupForm,
  seeAlso,
  isBoxWrapped = false,
  totalParagraphs,
  adsWrapperClass,
  isBrandedContent = false,
  readMoreArticle,
  fullScreenAds = false,
  displayArticleReadMore = false,
  displayNewVersionNewsletter = false,
  elementsClass,
}: ParagraphWrapperProps): React.ReactElement {
  const mainSection = sections?.[0];
  const isDisplayAds = !isBoxWrapped;
  const windowInnerSize = useWindowInnerWidth();
  const [adsHeight, setAdsHeight] = useState(0);

  const noAdsDisplayed = adsHeight <= 5;

  const adsExtraCss = {
    maxWidth: `${windowInnerSize.width}px`,
    marginLeft: fullScreenAds
      ? `calc(-1*(${windowInnerSize.width}px - 100%) / 2 )`
      : 0,
    height: fullScreenAds
      ? noAdsDisplayed
        ? 0
        : `${adsHeight + 125}px`
      : "auto",
  };

  const isHideAds = fullScreenAds && noAdsDisplayed;

  return (
    <>
      <Paragraph element={element} className={cn("mb-6", elementsClass)} />

      {isDisplayAds &&
      paragraphCount === 3 &&
      totalParagraphs !== 3 &&
      mainSection ? (
        <div
          className={cn("my-8 md:my-12", adsWrapperClass, {
            "min-h-0 border-y-0 bg-none py-0": isHideAds,
          })}
          style={adsExtraCss}
        >
          <Container rootClassName="sticky top-[120px]">
            <div className="relative mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
              <Advertisement
                setAdsHeight={setAdsHeight}
                adUnitProps={{
                  id: index !== 0 ? `dfp-ad-imu1-${index}` : `dfp-ad-imu1`,
                  type: AdvertisementTypeEnum.IMU1,
                  slot: GoogleAdsSlotFactory.imu1(
                    mainSection.uniqueName.replaceAll("_", "/")
                  ),
                  isRefresh: index !== 0,
                  slotTargettings: [
                    { key: "btarticleid", value: id },
                    {
                      key: "bttags",
                      value:
                        keywords
                          ?.map((tag: Tag) => getFormattedTextForAds(tag.name))
                          .join(",") || "",
                    },
                    {
                      key: "gs_channels",
                      value: gsChannels,
                    },
                  ],
                }}
                rootClassName="text-center"
              />
            </div>
          </Container>
        </div>
      ) : null}

      {paragraphCount === 6 ? (
        <>
          {displayRelatedLinksAndNewsletterSignupForm &&
          seeAlso?.title &&
          !isBrandedContent &&
          !displayArticleReadMore ? (
            <ArticleSeeAlso
              title={seeAlso.title}
              articleId={seeAlso.articleId}
              slug={seeAlso.slug}
            />
          ) : null}

          {displayRelatedLinksAndNewsletterSignupForm &&
          displayArticleReadMore &&
          totalParagraphs > 6 &&
          totalParagraphs <= 9 ? (
            <ArticleReadMore {...{ readMoreArticle }} />
          ) : null}

          {displayRelatedLinksAndNewsletterSignupForm &&
          sections &&
          keywords &&
          totalParagraphs > 9 ? (
            <div
              className={cn("py-10", {
                "md:-mx-8": displayNewVersionNewsletter,
              })}
            >
              <ArticleNewsletter
                rootClassName={
                  displayNewVersionNewsletter
                    ? "mb-6 border border-gray-175 p-6 md:p-8"
                    : "border-y-2 border-gray-850"
                }
                sections={sections}
                tags={keywords}
                displayNewVersionNewsletter={displayNewVersionNewsletter}
              />
            </div>
          ) : null}

          {isDisplayAds && totalParagraphs !== 6 && mainSection ? (
            <div
              className={cn("my-8 md:my-12", adsWrapperClass, {
                "min-h-0 border-y-0 bg-none py-0": isHideAds,
              })}
              style={adsExtraCss}
            >
              <Container rootClassName="sticky top-[120px]">
                <div className="relative mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
                  <Advertisement
                    setAdsHeight={setAdsHeight}
                    rootClassName="mb-6 text-center"
                    adUnitProps={{
                      id:
                        index !== 0
                          ? `dfp-ad-midarticlespecial-${index}`
                          : `dfp-ad-midarticlespecial`,
                      type: AdvertisementTypeEnum.MIDARTICLE,
                      slot: GoogleAdsSlotFactory.midarticlespecial(
                        mainSection.uniqueName.replaceAll("_", "/")
                      ),
                      isRefresh: index !== 0,
                      slotTargettings: [
                        { key: "btarticleid", value: id },
                        {
                          key: "bttags",
                          value:
                            keywords
                              ?.map((tag: Tag) =>
                                getFormattedTextForAds(tag.name)
                              )
                              .join(",") || "",
                        },
                        {
                          key: "gs_channels",
                          value: gsChannels,
                        },
                      ],
                    }}
                  />
                </div>
              </Container>
            </div>
          ) : null}
        </>
      ) : null}

      {paragraphCount === 9 ? (
        <>
          {displayRelatedLinksAndNewsletterSignupForm &&
          seeAlso?.title &&
          !displayArticleReadMore ? (
            <ArticleSeeAlso
              title={seeAlso.title}
              articleId={seeAlso.articleId}
              slug={seeAlso.slug}
            />
          ) : null}

          {displayRelatedLinksAndNewsletterSignupForm &&
          displayArticleReadMore &&
          totalParagraphs > 9 ? (
            <ArticleReadMore {...{ readMoreArticle }} />
          ) : null}

          {isDisplayAds && totalParagraphs !== 9 && mainSection ? (
            <div
              className={cn("my-8 md:my-12", adsWrapperClass, {
                "min-h-0 border-y-0 bg-none py-0": isHideAds,
              })}
              style={adsExtraCss}
            >
              <Container rootClassName="sticky top-[120px]">
                <div className="mx-auto w-full md:w-10/12 md:px-8 lg:w-8/12">
                  <Advertisement
                    setAdsHeight={setAdsHeight}
                    adUnitProps={{
                      id: index !== 0 ? `dfp-ad-imu2-${index}` : `dfp-ad-imu2`,
                      type: AdvertisementTypeEnum.IMU2,
                      slot: GoogleAdsSlotFactory.imu2(
                        mainSection.uniqueName.replaceAll("_", "/")
                      ),
                      isRefresh: index !== 0,
                      slotTargettings: [
                        { key: "btarticleid", value: id },
                        {
                          key: "bttags",
                          value:
                            keywords
                              ?.map((tag: Tag) =>
                                getFormattedTextForAds(tag.name)
                              )
                              .join(",") || "",
                        },
                        {
                          key: "gs_channels",
                          value: gsChannels,
                        },
                      ],
                    }}
                    rootClassName="text-center"
                  />
                </div>
              </Container>
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
}
