import { useInView } from "react-intersection-observer";
import { AccessFlag, Name, Type } from "@app/types/enums";
import Container from "@components/Container/Container";
import MetaTags from "@components/MetaTags/MetaTags";
import useGenerateGiftURL from "@hooks/useGenerateGiftURL";
import { useGetScrollValue } from "@hooks/useGetScrollValue";
import { useGrapeshotData } from "@hooks/useGrapeshot";
import { useHookUpdateUrlArticle } from "@hooks/useHookUpdateUrlArticle";
import { useMostReadArticleList } from "@hooks/useMostReadArticleList";
import useShowGiftNofitifcationUpdate from "@hooks/useShowGiftNofitifcationUpdate";
import ArticleAttachments from "@pages/Article/components/ArticleAttachments";
import ArticleAuthorsList from "@pages/Article/components/ArticleAuthorsList";
import ArticleKeywords, {
  KeywordDisplayOption,
} from "@pages/Article/components/ArticleKeywords";
import ArticleKicker from "@pages/Article/components/ArticleKicker";
import ArticleMainImage from "@pages/Article/components/ArticleMainImage/ArticleMainImage";
import ArticleParagraphs from "@pages/Article/components/ArticleParagraphs";
import ArticlesFromSameSectionNew from "@pages/Article/components/ArticlesFromSameSection/ArticlesFromSameSectionNew";
import ArticleSubscriberLabel from "@pages/Article/components/ArticleSubscriberLabel";
import ArticleSubshare from "@pages/Article/components/ArticleSubshare/ArticleSubshare";
import ArticleSubshareGiftingUpdate from "@pages/Article/components/ArticleSubshare/ArticleSubshareGiftingUpdate";
import ArticleSubshareSent from "@pages/Article/components/ArticleSubshare/ArticleSubshareSent";
import OutBrainWidget from "@pages/Article/components/OutBrainWidget";
import { ArticleProps } from "@pages/Article/types/Article";
import { checkDisplayPaywall } from "@pages/Article/utils/helpers";
import MostPopularNew from "@pages/Section/components/MostPopular/MostPopularNew";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import {
  checkIsHubArticle,
  gaEventTracker,
  getArticleJSONLD,
  getFieldValue,
  getFirstParagraph,
  getKickerFromArticleDataObject,
} from "@util/helpers";
import { find } from "lodash-es";

export default function ArticleDisplayMainNewsDefault({
  article,
  isGiftReceived,
  seeAlso,
  sectionNews,
  variant,
  index,
  isNavigate,
  setArticleObj,
  setArticleScrollWidth,
  isOutbrainEnabled = true,
  isPreview,
  gsChannels,
}: ArticleProps): React.ReactElement {
  // De-structure of article so it is cleaner to get the fields.
  const {
    id,
    title,
    updated: publishedDate,
    edited: updatedDate,
    media,
    authors,
    sections,
    tags,
    paywall,
    displaySetting,
    embedCodes,
    elements,
    relatedContributorProfile,
    urlPath,
    storyline,
    sanitizedRelatedStories,
    attachments,
  } = article;

  const { gsChannels: gsChannelsAPI } = useGrapeshotData(
    urlPath,
    index,
    gsChannels
  );

  const mainSection = sections?.[0];
  const articleCategory =
    mainSection?.parent.name !== "Home"
      ? mainSection?.parent.name
      : mainSection.name;

  const kicker = getKickerFromArticleDataObject(article);
  const contentAccess = paywall?.contentAccess || "0";
  const displayPaywall = checkDisplayPaywall({
    isGiftReceived,
    variant: variant,
    contentAccess: contentAccess,
  });
  const showRelatedLinksAndNewsletterSignupForm =
    displaySetting?.showRelatedLinksAndNewsletterSignupForm || false;

  const standFirstField =
    find(elements, { type: Type.Standfirst })?.fields || [];
  const standFirst = getFieldValue(standFirstField, Name.Standfirst);
  const isPremium = contentAccess === "1";
  const mostPopularData = useMostReadArticleList();

  const {
    alacrityRes,
    isModalOpen,
    setIsModalOpen,
    handleGenerateGiftURLModal,
  } = useGenerateGiftURL(isPremium);

  const { showGiftNotification, setShowGiftNotification } =
    useShowGiftNofitifcationUpdate(isPremium);

  const firstParagraph = getFirstParagraph(article.elements || []);
  const { ref } = useHookUpdateUrlArticle(urlPath, isNavigate);
  const isBrandedContent = checkIsHubArticle(article.elements || []);

  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const { prevScrollPos } = useGetScrollValue();
  const { ref: articleRef } = useInView({
    threshold: 0.1,
    onChange: (inView) => {
      if (inView) {
        setArticleObj(article);
      }
    },
  });

  const { ref: endOfArticle } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        gaEventTracker("end of article", "visible", article.urlPath);
      }
    },
  });

  const showGiftIcon =
    isPremium && OKTAUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;

  return (
    <>
      <MetaTags
        title={article?.seoFields?.metaTitle || title}
        articleId={article.id}
        description={article?.seoFields?.metaDescription || firstParagraph}
        keywords={article?.tags?.map((tag) => tag.name)}
        slug={article.urlPath}
        shortLink={"/article/" + article.id}
        canonicalUrl={article?.seoFields?.canonicalUrl}
        imageUrl={article?.media?.[0]?.content?.fields?.["original-caas"]?.url}
        imageAlt={
          article?.media?.[0]?.summary?.find(
            (summaryItem) => summaryItem.key === "alttext"
          )?.value
        }
        jsonLd={getArticleJSONLD(article)}
        robots={"index, follow, max-image-preview:large"}
        gsChannels={gsChannelsAPI}
        articleCategory={articleCategory}
        accessFlag={isPremium ? AccessFlag.Premium : AccessFlag.Free}
        publishedDate={publishedDate}
        updatedDate={updatedDate}
      />

      <ArticleSubshareGiftingUpdate
        isModalOpen={showGiftNotification}
        setIsModalOpen={setShowGiftNotification}
        {...{
          handleGenerateGiftURLModal,
        }}
      />

      <ArticleSubshareSent {...{ alacrityRes, isModalOpen, setIsModalOpen }} />

      <article
        className="display-type--mainNewsDefault w-full py-5 font-public-sans"
        data-index={index}
        data-cueid={id}
        ref={ref}
      >
        <div ref={articleRef}>
          <div
            ref={(e) => {
              if (e?.offsetHeight) {
                const height = e.offsetHeight;
                const scrollPercent = (prevScrollPos - e?.offsetTop) / height;
                const scrollPercentRounded = Math.round(scrollPercent * 100);
                setArticleScrollWidth(id, scrollPercentRounded);
              }
            }}
          >
            <Container rootClassName="md:pt-4">
              <Container rootClassName="px-0 mx-auto md:px-3 w-full md:w-10/12 lg:w-8/12 mb-4">
                <div className="flex flex-wrap items-center pb-2 md:px-6 md:pb-4">
                  <ArticleKicker
                    kicker={kicker}
                    className="font-base font-light uppercase"
                  />

                  {isPremium && kicker ? (
                    <div
                      className="ml-3 mr-2 font-poppins text-2xl font-light leading-normal tracking-[0.075rem] text-gray-650"
                      data-testid="kicker-subscriber-label-separator"
                    >
                      ·
                    </div>
                  ) : null}

                  <ArticleSubscriberLabel
                    isPremium={isPremium}
                    displayDot={false}
                    rootClassName={"font-base font-light"}
                  />
                </div>

                <h1
                  data-testid="article-title"
                  className="mb-2 font-lct text-4xl font-bold !leading-[1.3] tracking-[-0.045rem] text-gray-850 md:px-6 md:text-10xl"
                >
                  {title}
                </h1>

                <>
                  {typeof standFirst === "string" ? (
                    <p className="mb-6 mt-2 font-lucida text-xl tracking-[-0.075rem] text-gray-850 md:px-6 md:text-4xl md:leading-normal">
                      {standFirst}
                    </p>
                  ) : null}
                </>

                <>
                  {publishedDate && updatedDate ? (
                    <ArticleAuthorsList
                      rootClassName="mb-6 md:px-6 min-h-[50px] text-gray-850"
                      authors={authors}
                      contributors={relatedContributorProfile}
                      updatedDate={updatedDate}
                      publishedDate={publishedDate}
                      imgHeight={50}
                      imgWidth={50}
                      alwaysShowDefaultPhoto
                      hasUpdatedTimeDisplayed={
                        displaySetting?.hasUpdatedTimeDisplayed
                      }
                    />
                  ) : null}
                </>

                <>
                  {tags?.length || showGiftIcon ? (
                    <div
                      className={
                        "mb-3 flex items-center justify-between border-b border-t border-gray-250 py-3 md:mx-6 md:mb-6 md:py-4"
                      }
                    >
                      {tags ? (
                        <ArticleKeywords
                          keywords={tags}
                          keywordDisplayOption={KeywordDisplayOption.First}
                          firstKeyworkDisplayClassname={"w-full"}
                          rootClassName={"w-full bg-transparent !p-0"}
                          tagClassName={"border-r-0 px-0 md:px-3"}
                          followtextButton={true}
                          toolTipClassName={
                            "flex flex-col items-end right-0 left-auto"
                          }
                          toolTipArrowClassName={
                            "md:mr-10 border-b-[8px] border-l-[6px] border-r-[6px] border-x-transparent border-b-black border-t-transparent mr-3"
                          }
                          disableTooltip
                          withGiftIcon={showGiftIcon}
                          disableBorder
                        />
                      ) : null}

                      <ArticleSubshare
                        displayNewGiftIcon
                        isPremium={isPremium}
                        userType={OKTAUserInfo?.usertype}
                        {...{
                          handleGenerateGiftURLModal,
                          setIsModalOpen,
                          alacrityRes,
                        }}
                      />
                    </div>
                  ) : null}
                </>

                <>
                  {media ? (
                    <ArticleMainImage
                      media={media}
                      rootClassName={"aspect-3x2 -mx-3 md:mx-0"}
                      imgRootClassName="mx-auto h-full max-h-[20rem] md:max-h-[29rem] lg:max-h-[33rem] relative overflow-hidden"
                      captionRootClassname="py-2 px-3 font-poppins text-xs text-gray-850 md:px-8"
                      thumbnailRootClassName="object-contain flex items-center justify-center relative overflow-hidden h-full"
                      landscapeImgRootClassName={`w-full mx-auto object-contain overflow-hidden ${media.length > 1 ? "aspect-3x2" : ""}`}
                      thumbnailArrowRootClassNameLeft="md:-ml-[2.75rem] min-[1040px]:-left-4 left-0 w-8 h-8 bg-white"
                      thumbnailArrowRootClassNameRight="md:-mr-[2.75rem] min-[1040px]:-right-4 right-0 w-8 h-8 bg-white"
                      displayBlurBg
                      forceDisplayArrow={false}
                      withPagination
                      thumbnailArrowHoverState={
                        "rounded-full border hover:border-gray-850"
                      }
                      withZoom={false}
                    />
                  ) : null}
                </>

                <ArticleAttachments
                  attachments={attachments}
                  rootClassName="md:mx-8"
                />

                <>
                  {elements && storyline ? (
                    <ArticleParagraphs
                      className="body-content tracking-tighter mb-6 mt-4 font-lucida text-lg font-medium leading-normal tracking-[-0.05625rem] text-gray-850 md:px-8 md:text-xl"
                      {...{
                        id,
                        elements,
                        seeAlso,
                        embedCodes,
                        displayPaywall,
                        sections,
                        index,
                        gsChannels: gsChannelsAPI,
                        isPremium,
                        storyline: storyline,
                        displayRelatedLinksAndNewsletterSignupForm:
                          showRelatedLinksAndNewsletterSignupForm,
                        keywords: tags,
                        isGifted: isGiftReceived,
                        adsWrapperClass:
                          "min-h-[375px] w-screen bg-gray-125 py-6 transition-all duration-300 border-y border-gray-175",
                        isBrandedContent,
                        displayArticleReadMore: true,
                        readMoreArticle: sanitizedRelatedStories,
                        fullScreenAds: true,
                        displayNewVersionNewsletter: true,
                        elementsClass: "mb-4 md:mb-6",
                        forceHeadingCss: true,
                        displayPurchase: {
                          isDisplayed: paywall?.copyright === "SPH",
                          publishedDate: publishedDate,
                          title: title,
                        },
                        isPreview,
                      }}
                    />
                  ) : null}
                </>

                <div ref={endOfArticle}></div>
              </Container>

              <Container rootClassName="flex md:gap-6 lg:flex-row flex-col-reverse">
                <>
                  {!isBrandedContent ? (
                    <ArticlesFromSameSectionNew
                      data={sectionNews}
                      sections={sections}
                    />
                  ) : null}
                </>

                <>
                  {mostPopularData ? (
                    <div className="mx-auto mb-6 lg:min-w-[300px] lg:max-w-[300px]">
                      <MostPopularNew
                        data={mostPopularData}
                        rootClassName="my-5"
                      />
                    </div>
                  ) : null}
                </>
              </Container>
              <>
                {article.urlPath && isOutbrainEnabled ? (
                  <OutBrainWidget
                    articleUrl={article.urlPath}
                    widgetId={index === 0 ? "AR_4" : "AR_5"}
                  />
                ) : null}
              </>
            </Container>
          </div>
        </div>
      </article>
    </>
  );
}
