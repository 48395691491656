import { ArticleDataObject } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { isArticleUpdated, parseStoryThread } from "@util/helpers";
import cx from "classnames";

export default function OpinionsFeaturesStories({
  article,
  index,
  disablePaywall = false,
}: {
  article: ArticleDataObject;
  index: number;
  disablePaywall?: boolean;
}): React.ReactElement {
  const variant: CardVariations = {
    kicker: {
      color: "text-gray-515",
    },
    title: {
      color: "text-gray-850",
      size: "text-base",
    },
    byline: {
      size: "text-xs",
      imageWidth: 32,
      imageHeight: 32,
    },
  };
  const storyThread = parseStoryThread(article.others?.storyThread);

  return (
    <div
      key={index}
      className={cx(
        "story border-b border-gray-175 py-3 lg:col-span-1 lg:border-none",
        {
          "lg:order-1": index < 6,
          "lg:order-3": index === 6,
          "border-none": index === 2,
        }
      )}
    >
      <BasicCard
        id={article.id}
        slug={article.slug}
        title={article.displaySetting?.displayHeadline || article.title}
        kicker={article.kicker?.fields?.[0]?.value}
        section={article?.sections?.[0]}
        storyThread={storyThread}
        paywall={
          !disablePaywall ? article.paywall?.contentAccess === "1" : false
        }
        variations={variant}
        rootClassName={cx("h-full border-gray-175", {
          "lg:border-r lg:pr-3": index < 2,
          "lg:pl-3": index > 0,
        })}
        author={article.authors}
        contributorProfile={article.relatedContributorProfile}
        edited={
          isArticleUpdated(article.updated, article.edited)
            ? article.edited
            : undefined
        }
        hasUpdatedTimestampDisplay={
          article.displaySetting?.hasUpdatedTimeDisplayed
        }
      />
    </div>
  );
}
