/**
 * Company: SPHMedia
 * Description: TopstoryCard component
 */

import { Type } from "@app/types/enums";
import { CaaSImageFilters } from "@app/types/OptimisedImage";
import { getCardThumbnail } from "@components/ArticleCard/helpers/helpers";
import { BasicCardProps } from "@components/ArticleCard/types";
import ArticleThumbnail from "@components/ArticleThumbnail/ArticleThumbnail";
import { faCircle, faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Category from "@src/app/components/ArticleMeta/Category";
import Description from "@src/app/components/ArticleMeta/Description";
import Kicker from "@src/app/components/ArticleMeta/Kicker";
import ReadMore from "@src/app/components/ArticleMeta/ReadMore";
import SocialMediaIcons from "@src/app/components/ArticleMeta/SocialMediaIcons";
import SubscriberFlag from "@src/app/components/ArticleMeta/SubscriberFlag";
import TitleLink from "@src/app/components/ArticleMeta/TitleLink";
import cx from "classnames";

/**
 * TopStoryCard
 * Description: [Description of what the component does]
 * @param props - The props required by the component (if any)
 * @returns The rendered React element
 */
export default function TopStoryCard({
  rootClassName = "",
  id,
  media,
  kicker = "",
  tag,
  title = "",
  paywall = false,
  blurb = "",
  defaultImage,
  slug,
}: BasicCardProps): React.ReactElement {
  const cardThumbnail = getCardThumbnail(title, media, defaultImage);
  const lifestyleNewsImageFilter: CaaSImageFilters[] = [
    { breakpoint: "(min-width: 992px)", w: 588, h: 392, dpr: 1 },
    { breakpoint: "(min-width: 768px)", w: 696, h: 464, dpr: 1 },
    { w: 401, h: 267, dpr: 1 },
  ];
  const _media = media && "length" in media ? media?.[0] : media;

  const isVideo =
    _media &&
    (_media.content?.type === Type.ArticleBrightcoveVideo ||
      _media.content?.type === Type.ArticleYoutubeVideo);

  return (
    <div data-testid="topstory-card">
      <div
        className={cx(
          "justify-self group relative border border-translucent px-4 pt-4 hover:border-lifestyle-primary lg:h-full lg:p-4 lg:pb-8",
          rootClassName
        )}
      >
        {tag ? (
          <Category
            categoryName={tag.name}
            hrefLink={"/" + tag.uri?.replace("main/", "keywords/")}
            rootClassName={cx(
              "px-2 text-base z-10 bg-lifestyle-primary font-pt-sans py-0.5 hover:no-underline transition-colors border border-lifestyle-primary",
              "absolute -top-[1px] -left-[1px]",
              "hover:bg-white hover:text-lifestyle-primary"
            )}
          />
        ) : null}

        {cardThumbnail ? (
          <div className={cx("relative")}>
            <ArticleThumbnail
              thumbnail={cardThumbnail}
              imgRootClassName="w-full"
              orientation="landscape"
              rootClassName="-mt-10"
              filters={lifestyleNewsImageFilter}
            />
            {isVideo ? (
              <div>
                <FontAwesomeIcon
                  icon={faCircle}
                  inverse
                  className="fa-stack-2x"
                />
                <FontAwesomeIcon icon={faPlay} className="fa-stack-1x" />
              </div>
            ) : null}
          </div>
        ) : null}
        <div className="mb-10 flex flex-col space-y-1 pt-4">
          {kicker ? (
            <Kicker
              name={kicker}
              rootClassName="font-pt-sans text-gray-515 tracking-[4px]"
            />
          ) : null}

          <TitleLink
            title={title}
            articleId={id}
            rootClassName="text-6xl lg:text-8xl hover:text-lifestyle-primary font-pt-serif"
            slug={slug}
          />

          {paywall ? (
            <SubscriberFlag rootClassName="inline text-4xs font-normal uppercase mt-1" />
          ) : null}

          <Description
            text={blurb}
            rootClassName="text-xs w-fit hidden lg:block word-break"
          />

          <div className="absolute top-full !mt-0 flex w-full -translate-y-1/2 items-center">
            <div className="flex-1">
              <ReadMore
                articleId={id}
                isAnimated={false}
                rootClassName="bg-white font-pt-sans !w-auto !py-1.5 !tracking-normal"
                slug={slug}
              />
            </div>

            <SocialMediaIcons rootClassName="flex-none" />
          </div>
        </div>
      </div>
    </div>
  );
}
