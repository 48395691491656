/**
 * Company: SPHMedia
 * Description: LifestyleLuxe Section Layout
 */

import { ReactElement, useState } from "react";
import { Await } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import { ArticleDataObject } from "@app/types/Cue";
import { AdvertisementTypeEnum } from "@app/types/enums";
import { LifestyleLuxeContext } from "@app/types/Page";
import Advertisement from "@components/Advertisement/Advertisement";
import PrestitialAdWrapper from "@components/Advertisement/PrestitialAdWrapper";
import LuxeCard from "@components/ArticleCard/LuxeCard";
import Breadcrumb from "@components/Breadcrumb/Breadcrumb";
import Container from "@components/Container/Container";
import CustomError from "@components/Error/CustomError";
import GAData from "@components/GAData/GAData";
// Components import
import Header from "@components/Header/Header";
import {
  useBreadCrumbState,
  useCategoriesFromPath,
} from "@components/Header/helpers";
import LoadMore from "@components/LoadMore/LoadMore";
import MetaTags from "@components/MetaTags/MetaTags";
import useGlobalAdSetting from "@hooks/useGlobalAdSetting";
import LifestyleNews from "@pages/Section/components/Lifestyle/LifestyleNews";
import useOKTAUserStore, { OKTAUserTypeEnum } from "@store/useOKTAUserStore";
import { sectionNavigationItems } from "@util/constant";
import { useContext } from "@util/getContext";
import { GoogleAdsSlotFactory, toTitleCase } from "@util/helpers";
import { useDetectAdBlock } from "adblock-detect-react";
import axios from "axios";

const articleSet = new Set<string>();

export default function LifestyleLuxeLayout(): ReactElement {
  const OKTAUserInfo = useOKTAUserStore((state) => state.userInfo);
  const isSubscriber = OKTAUserInfo?.usertype === OKTAUserTypeEnum.SUBSCRIBER;
  const context: LifestyleLuxeContext = useContext();
  const { parentCategory, childCategory } = useCategoriesFromPath();
  const { isPrestitialEnabled, topOverlayImpressions, topOverlayValidity } =
    useGlobalAdSetting({
      targeting: { key: "page", value: "listing" },
    });

  const [post, setPost] = useState<ArticleDataObject[]>([]);
  const lifestyleLuxeData = context.data.lifestyleLuxe;
  const initialPosts = lifestyleLuxeData?.slice(1, 15);

  const firstArticle = lifestyleLuxeData?.slice(0, 1)?.[0];
  const firstArticleMainSection = firstArticle?.sections?.[0];

  if (post.length === 0 && !post?.[post.length - 1]?.sort) {
    lifestyleLuxeData?.forEach((art: ArticleDataObject) => {
      articleSet.add(art.id);
    });
    setPost(initialPosts || []);
  }

  const loadMore = async () => {
    const lastArticle =
      post.length === 0
        ? lifestyleLuxeData?.[lifestyleLuxeData.length - 1]
        : post?.[post.length - 1];

    const response = await axios.post("/_plat/api/v1/more-stories", {
      category: "lifestyle_bt-luxe",
      parentCategory,
      sort: lastArticle?.sort,
      size: 12,
    });

    const openSearchResponse = response.data;

    if (openSearchResponse.length > 0) {
      const updatedPost = [...post];

      openSearchResponse.forEach((art: ArticleDataObject) => {
        if (!articleSet.has(art.id)) {
          updatedPost.push(art);
          articleSet.add(art.id);
        }
      });
      setPost(updatedPost);
    }
  };

  const title = toTitleCase(
    (childCategory || parentCategory).replace("-", " ") + " Index"
  );

  const adBlockDetected = useDetectAdBlock();
  const { breadcrumbsRef, isShowBreadCrumb } = useBreadCrumbState();
  return (
    <Await resolve={context} errorElement={<CustomError />}>
      {({ kind, error }) => {
        return kind !== "section" || error ? (
          <h1>Error</h1>
        ) : (
          <div className="w-full">
            <MetaTags
              title="BT Luxe Latest News & Headlines - THE BUSINESS TIMES"
              description="THE BUSINESS TIMES BT Luxe - Find BT Luxe News & Headlines, insight and analysis in Singapore, Asia-Pacific & global markets news at The Business Times."
              ogType="article"
              slug={RouteFactory.lifestyleLuxe}
            />

            <GAData
              chapter1={childCategory?.replaceAll("-", " ") || ""}
              level2={parentCategory?.replaceAll("-", " ") || ""}
              title={title}
              adblocker={adBlockDetected ? 1 : 0}
            />

            <Advertisement
              rootClassName="min-h-[90px] bg-gray-125 py-1 md:py-6 border-y border-gray-175"
              adUnitProps={{
                type: AdvertisementTypeEnum.LB1,
                slot: GoogleAdsSlotFactory.lb1("lifestyle/bt-luxe"),
                adsClassName: "min-h-[90px]",
              }}
            />

            <PrestitialAdWrapper
              slot={GoogleAdsSlotFactory.prestitial("lifestyle/bt-luxe")}
              {...{
                isPrestitialEnabled,
                topOverlayImpressions,
                topOverlayValidity,
              }}
            />

            <Header
              displayBreadcrumb={
                sectionNavigationItems?.[parentCategory] && isShowBreadCrumb
              }
              parentCategory={parentCategory}
              childCategory={childCategory}
              categoryDataMapping={sectionNavigationItems}
            />

            {parentCategory && sectionNavigationItems?.[parentCategory] ? (
              <div className="flex-none px-4">
                <Container>
                  <h1 className="pb-3 pt-6 font-playfair text-6xl font-semibold uppercase text-gray-850 md:text-8xl">
                    Lifestyle
                  </h1>

                  <div ref={breadcrumbsRef}>
                    <Breadcrumb
                      rootClassName="m-auto container"
                      parentCategory={parentCategory}
                      childCategory={childCategory}
                      categoryDataMapping={sectionNavigationItems}
                    />
                  </div>
                </Container>
              </div>
            ) : null}

            {firstArticle ? (
              <LuxeCard
                id={firstArticle.id}
                title={
                  firstArticle.displaySetting?.displayHeadline ||
                  firstArticle.title
                }
                media={firstArticle.media}
                kicker={firstArticle.kicker?.fields?.[0]?.value}
                section={firstArticle.sections?.[0]}
                paywall={
                  isSubscriber
                    ? firstArticle.paywall?.contentAccess === "1"
                    : false
                }
                blurb={firstArticle.blurb}
                rootClassName="mb-16"
                defaultImage={
                  firstArticleMainSection
                    ? { directoryName: firstArticleMainSection.uniqueName }
                    : undefined
                }
                slug={firstArticle.slug}
              />
            ) : null}

            <Container rootClassName="pb-8">
              <LifestyleNews
                data={post}
                parentCategory={parentCategory}
                childCategory={childCategory}
              />

              <LoadMore loadText="More Stories" onLoadMore={loadMore} />
            </Container>

            <Advertisement
              adUnitProps={{
                type: AdvertisementTypeEnum.CATFISH,
                slot: GoogleAdsSlotFactory.catfish("lifestsyle/bt-luxe"),
              }}
            />

            <Advertisement
              adUnitProps={{
                type: AdvertisementTypeEnum.ABM,
                slot: GoogleAdsSlotFactory.abm(),
              }}
            />
          </div>
        );
      }}
    </Await>
  );
}
