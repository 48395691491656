import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { RouteFactory } from "@app/routePaths";
import type { ArticleDataObject } from "@app/types/Cue";
import LoadMore from "@components/LoadMore/LoadMore";
import {
  fetchMoreBrandedContentData,
  fetchMoreStoriesData,
} from "@pages/Section/Section.server";
import { compact } from "lodash-es";

import SectionListingStories from "./SectionListingStories";

export type OverviewProps = {
  testId?: string | null;
  data?: ArticleDataObject[];
};

const PAGE_SIZE = 10;

export default function Overview({
  testId = null,
  data = [],
}: OverviewProps): React.ReactElement {
  const matchedCategory = useLocation().pathname.replace("/", "");
  const [parentCategory = "", childCategory = ""] = matchedCategory.split("/");
  const [hasMoreStories, setHasMoreStories] = useState<boolean>(true);
  const [renderedArticleIds, setRenderedArticleIds] = useState(
    new Set<string>()
  );
  const [loadMoreData, setLoadMoreData] = useState<ArticleDataObject[]>([]);
  const [page, setPage] = useState<Array<number | string> | number>(
    data?.[data.length - 1]?.sort ?? 0
  );

  //compact removes all falsey values
  const uniqueCategoryQuery = compact([
    parentCategory !== "keywords" && parentCategory,
    childCategory,
  ]).join("_");

  const handleLoadMore = async () => {
    const lastArticle =
      loadMoreData.length === 0
        ? data?.[data.length - 1]
        : loadMoreData?.[loadMoreData.length - 1];

    if (lastArticle) {
      const newArticles: ArticleDataObject[] = [];
      const moreArticles =
        parentCategory !== RouteFactory.hub.replace("/", "")
          ? await fetchMoreStoriesData(
              uniqueCategoryQuery,
              parentCategory,
              lastArticle
            )
          : await fetchMoreBrandedContentData(lastArticle);

      moreArticles.forEach((article: ArticleDataObject) => {
        if (!renderedArticleIds.has(article.id)) {
          newArticles.push(article);
          setRenderedArticleIds(
            (prevState) => new Set([...prevState, article.id])
          );
        }
      });
      setLoadMoreData([...loadMoreData, ...newArticles]);
      setPage(newArticles?.[newArticles.length - 1]?.sort ?? 0);
      if (newArticles.length < PAGE_SIZE) {
        setHasMoreStories(false);
      }
    }
  };

  /**
   * Sets a precaution for duplicate article ids when rendering additional articles
   */
  useEffect(() => {
    const articleIdsToAdd: string[] = [];

    data.forEach((article) => {
      articleIdsToAdd.push(article.id);
    });

    setRenderedArticleIds(
      (prevState) => new Set([...prevState, ...articleIdsToAdd])
    );
  }, [data]);

  return (
    <section data-testid={testId}>
      <div className="stories">
        {[...data, ...loadMoreData].map(
          (article: ArticleDataObject, index: number) => {
            return (
              <SectionListingStories
                key={index}
                index={index}
                article={article}
              />
            );
          }
        )}
      </div>
      {page !== 0 && data.length === 10 ? (
        <LoadMore
          rootClassName="my-4"
          onLoadMore={handleLoadMore}
          hasMore={hasMoreStories}
        />
      ) : null}
    </section>
  );
}
