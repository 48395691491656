import Slider, { Settings } from "react-slick";
import { ArticleDataObject } from "@app/types/Cue";
import BasicCard from "@components/ArticleCard/BasicCard";
import { CardVariations } from "@components/ArticleCard/types";
import { parseStoryThread } from "@util/helpers";
import cx from "classnames";

export interface BtLuxeCarouselProps {
  rootClassName?: string;
  data: ArticleDataObject[];
  testid?: string;
  variations: CardVariations;
  dotsCustomClass: string;
  btLuxeClass: string;
  disablePaywall?: boolean;
}

export default function BtLuxeCarousel({
  data,
  testid = "lifestyle-carousel",
  variations,
  dotsCustomClass,
  btLuxeClass,
  disablePaywall = false,
}: BtLuxeCarouselProps): React.ReactElement {
  const SliderCarouselComponent =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    typeof window === "undefined" ? Slider.default : Slider;

  const settings: Settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    swipeToSlide: true,
    adaptiveHeight: true,
    arrows: false,
    dotsClass: cx(
      dotsCustomClass,
      "!flex pt-3 justify-center lg:w-2/4 lg:ml-auto"
    ),
    customPaging: () => (
      <button className="!before:hidden duration-all !m-[5px] !h-2 !w-2 scale-100 !rounded-full bg-gray-550 !p-0 transition-all"></button>
    ),
  };

  return (
    <div data-testid={testid}>
      <SliderCarouselComponent {...settings}>
        {data.map((article) => {
          const {
            id,
            title,
            media,
            kicker,
            sections,
            paywall,
            blurb,
            displaySetting,
            others,
            slug,
          } = article;

          const uniqueName = sections?.[0]?.uniqueName ?? "";
          const section = {
            extraClass: cx({
              [cx(
                "bg-transparent bg-no-repeat no-underline text-transparent",
                "max-w-full mb-2.5 block w-[70px] h-[21px] lg:w-[104px] lg:h-[31px]",
                "bg-[url(/src/app/assets/logo-btluxe-white.svg)]",
                btLuxeClass
              )]: uniqueName === "lifestyle_bt-luxe",
            }),
          };
          const storyThread = parseStoryThread(others?.storyThread);

          return (
            <div className="w-full" key={id}>
              <BasicCard
                rootClassName="items-stretch"
                id={id}
                title={displaySetting?.displayHeadline || title}
                section={sections?.[0]}
                storyThread={storyThread}
                paywall={
                  !disablePaywall ? paywall?.contentAccess === "1" : false
                }
                kicker={kicker?.fields?.[0]?.value}
                blurb={blurb}
                media={media}
                variations={{ ...variations, section }}
                defaultImage={{
                  directoryName: uniqueName,
                }}
                slug={slug}
              />
            </div>
          );
        })}
      </SliderCarouselComponent>
    </div>
  );
}
