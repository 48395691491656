import { RouteFactory } from "@app/routePaths";
import thriveCurveWord from "@assets/thrive_images/thrive-curve-word.webp";
import GAData from "@components/GAData/GAData";
import MetaTags from "@components/MetaTags/MetaTags";
import ThriveAboutUsDescription from "@pages/Thrive/AboutUs/components/ThriveAboutUsDescription";
import ThriveAboutUsTeamCards from "@pages/Thrive/AboutUs/components/ThriveAboutUsTeamCards/ThriveAboutUsTeamCards";
import ThriveCenterSubscription from "@pages/Thrive/components/ThriveCenterSubscription";
import ThriveFooter from "@pages/Thrive/components/ThriveFooter";
import ThriveGetSocial from "@pages/Thrive/components/ThriveGetSocial";
import ThriveHeader from "@pages/Thrive/components/ThriveHeader";

export default function ThriveAboutUs(): React.ReactElement {
  return (
    <div
      className="w-full overflow-hidden bg-verticals-thrive antialiased"
      id="thrive-about-us-page"
      data-testid="thrive-about-us-page"
    >
      <MetaTags
        title="Thrive: About Us - THE BUSINESS TIMES"
        description="Read more at The Business Times."
        slug={RouteFactory.thriveAboutUs}
        shortLink={RouteFactory.thriveAboutUs}
      />

      <GAData title="Thrive_AboutUs" level2="miscellaneous" />

      <ThriveHeader />

      <ThriveAboutUsDescription />

      <div className="overflow-hidden py-40">
        <img
          className="w-full scale-[3] lg:scale-[1.3]"
          src={thriveCurveWord}
          alt="One two thrive"
          height={99}
          width={1800}
        />
      </div>

      <ThriveAboutUsTeamCards />

      <div className="overflow-hidden font-inter">
        <ThriveCenterSubscription />
      </div>

      <ThriveGetSocial />

      <div className="flex justify-center bg-gray-850 font-poppins">
        <ThriveFooter />
      </div>
    </div>
  );
}
