import { matchPath } from "react-router-dom";
import type {
  ArticleDataObject,
  Element,
  Field,
  KeyValuePair,
  ProfileContent,
  Section,
  StoryThread,
  Tag,
} from "@app/types/Cue";
import { KickerEnums, Name, NewsletterType, Type } from "@app/types/enums";
import {
  dayjsSingaporeTimezone,
  defaultTimestampFormat,
  ENVIRONMENT,
  LIVE_DOMAIN,
  sectionNavigationItems,
  TRUNCATE_LENGTH,
} from "@app/util/constant";
import pdfIcon from "@assets/application-pdf.png";
import jsonLdLogo from "@assets/bt-logo-72.jpg";
import aseanBusinessDefaultImage from "@assets/defaultImages/asean_business/default_image.jpeg";
import bTLuxeDefaultImage from "@assets/defaultImages/bt_luxe/default_BTLUXE.jpg";
import companiesMarketsDefaultImage from "@assets/defaultImages/companies-markets/default_image.jpg";
import companiesMarketsBankingFinanceDefaultImage from "@assets/defaultImages/companies-markets_banking-finance/default_image.jpg";
import companiesMarketsCapitalMarketsCurrenciesDefaultImage from "@assets/defaultImages/companies-markets_capital-markets-currencies/default_image.jpg";
import companiesMarketsEnergyCommoditiesDefaultImage from "@assets/defaultImages/companies-markets_energy-commodities/default_image.jpg";
import companiesMarketsReitsPropertyDefaultImage from "@assets/defaultImages/companies-markets_reits-property/default_image.jpg";
import companiesMarketsTelcosMediaTechDefaultImage from "@assets/defaultImages/companies-markets_telcos-media-tech/default_image.jpg";
import companiesMarketsTransportLogisticsDefaultImage from "@assets/defaultImages/companies-markets_transport-logistics/default_image.jpg";
import consumerHealthDefaultImage from "@assets/defaultImages/consumer_healthcare/default_image.jpg";
import garageDefaultImage from "@assets/defaultImages/garage/default_image.jpeg";
import globalEnterpriseDefaultImage from "@assets/defaultImages/global_enterprise/default_image.jpeg";
import internationalDefaultImage from "@assets/defaultImages/international/default_image.jpg";
import lifestyleDefaultImage from "@assets/defaultImages/lifestyle/default_Lifestyle.jpg";
import opinionFeaturesDefaultImage from "@assets/defaultImages/opinion_features/default_image.jpg";
import opinionFeaturesColumnsDefaultImage from "@assets/defaultImages/opinion-features_columns/default_image.jpg";
import opinionFeaturesFeaturesDefaultImage from "@assets/defaultImages/opinion-features_features/default_image.jpg";
import podcastsDefaultImage from "@assets/defaultImages/podcasts/default_podcasts.jpg";
import propertyDefaultImage from "@assets/defaultImages/property/default_image.jpeg";
import sgsmeDefaultImage from "@assets/defaultImages/sgsme/default_image.jpeg";
import singaporeDefaultImage from "@assets/defaultImages/singapore/default_image.png";
import singaporeEconomyPolicyDefaultImage from "@assets/defaultImages/singapore_economy-policy/default_image.jpg";
import startupsTechDefaultImage from "@assets/defaultImages/startups_tech/default_image.jpg";
import startupsTechTechnologyDefaultImage from "@assets/defaultImages/startups-tech_technology/default_image.jpg";
import wealthCryptoAlternativeAssetsDefaultImage from "@assets/defaultImages/wealth_crypto-alternative-assets/default_image.jpg";
import wealthPersonalFinanceDefaultImage from "@assets/defaultImages/wealth_personal-finance/default_image.jpg";
import wealthWealthInvestingDefaultImage from "@assets/defaultImages/wealth_wealth-investing/default_image.jpg";
import workingLifeDefaultImaage from "@assets/defaultImages/working_life/default_image.jpg";
import imageIcon from "@assets/image-x-generic.png";
import { HttpStatusCode } from "axios";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(relativeTime, {
  thresholds: [
    { l: "s", r: 1 },
    { l: "ss", r: 59, d: "second" },
    { l: "m", r: 1 },
    { l: "mm", r: 59, d: "minute" },
    { l: "h", r: 1 },
    { l: "hh", r: 23, d: "hour" },
    { l: "d", r: 1 },
    { l: "dd", r: 29, d: "day" },
    { l: "M", r: 1 },
    { l: "MM", r: 11, d: "month" },
    { l: "y", r: 1 },
    { l: "yy", d: "year" },
  ],
  rounding: (number) => Math.floor(number),
});

// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s ago",
    ss: "%d seconds",
    s: "a few seconds",
    m: "a minute",
    mm: "%d minutes",
    h: "an hour",
    hh: "%d hours",
    d: "a day",
    dd: "%d days",
    M: "a month",
    MM: "%d months",
    y: "a year",
    yy: "%d years",
  },
});
// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(timezone);
// eslint-disable-next-line import/no-named-as-default-member
dayjs.extend(utc);
dayjs.extend(duration);

import { RouteFactory, routesVerticals } from "@app/routePaths";
import { PodcastSize } from "@app/types/Embed";
import { CaaSImageFilters } from "@app/types/OptimisedImage";
import { BrightcoveVideo } from "@components/Brightcove/utils/types";
import {
  BT_NEWSLETTERS,
  NEWSLETTER_TYPE,
} from "@components/Newsletter/types/Newsletter";
import classNames from "classnames";
import { isEmpty, kebabCase, trim, uniq } from "lodash-es";
import { twMerge } from "tailwind-merge";

type ErrorWithMessage = {
  message: string;
};

export const renderPageTitle = (path = "", params?: Record<string, string>) => {
  if (matchPath({ path: RouteFactory.section("*") }, path)) {
    // e.g. Companies & Markets Latest News & Headlines - THE BUSINESS TIMES
    let category: string = params?.parentCategory ?? "";

    if (params?.childCategory) {
      category += `/${params.childCategory}`;
    }

    if (sectionNavigationItems?.[category]) {
      return `${sectionNavigationItems[category]?.label} Latest News & Headlines - THE BUSINESS TIMES`;
    }

    return "THE BUSINESS TIMES";
  }

  if (matchPath({ path: RouteFactory.search() }, path)) {
    return `Search: ${params?.query} THE BUSINESS TIMES`;
  }

  if (matchPath({ path: RouteFactory.article("*") }, path)) {
    // we may change this to same as production convention
    return params?.id ?? "";
  }

  return "THE BUSINESS TIMES - Get the Latest Business &amp; Financial News";
};

export const isElementInViewport = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const isErrorWithMessage = (
  error: unknown
): error is ErrorWithMessage => {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof (error as Record<string, unknown>).message === "string"
  );
};

/**
 * Helper function to check if link href is external or not.
 * @param to The href link.
 * @returns {boolean}
 */
export const isExternalLink = (to: string): boolean => /^https?:\/\//.test(to);

export const toErrorWithMessage = (maybeError: unknown): ErrorWithMessage => {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
};

export const getErrorMessage = (error: unknown) => {
  return toErrorWithMessage(error).message;
};

/**
 * Generate the Google Ads slot id based on the parent section unique name.
 * In-article ads is having a different slot prefix with `midarticlespecial`
 */
export const GoogleAdsSlotFactory = {
  prefix: "/5908/bt",
  midarticlespecial: (sectionUniqueName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/midarticlespecial/${sectionUniqueName}`;
  },
  imu1: (sectionUniqueName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/imu1/${sectionUniqueName}`;
  },
  imu2: (sectionUniqueName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/imu2/${sectionUniqueName}`;
  },
  imu3: (sectionUniqueName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/imu3/${sectionUniqueName}`;
  },
  lb1: (sectionUniqueName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/lb1/${sectionUniqueName}`;
  },
  lb2: (sectionUniqueName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/lb2/${sectionUniqueName}`;
  },
  prestitial: (sectionUniqueName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/prestitial/${sectionUniqueName}`;
  },
  catfish: (sectionUniqueName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/catfish/${sectionUniqueName}`;
  },
  abm: () => {
    return `${GoogleAdsSlotFactory.prefix}/abm`;
  },
  bn1: (sectionUniqueName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/bn1/${sectionUniqueName}`;
  },
  bn2: (sectionUniqueName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/bn2/${sectionUniqueName}`;
  },
  bn3: (sectionUniqueName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/bn3/${sectionUniqueName}`;
  },
  bn4: (sectionUniqueName: string) => {
    return `${GoogleAdsSlotFactory.prefix}/bn4/${sectionUniqueName}`;
  },
};

/**
 * Helper function to change all space to
 * underscore and capitalize every word
 * @param text
 * @returns titleCase
 */
export function toTitleCase(text: string) {
  return text
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join("_");
}

/**
 * Helper function to change all space to underscore
 * @param text
 * @returns titleCase
 */
export function toUnderscoreFromSpace(text: string) {
  return escape(
    text
      .replaceAll(/(\r\n|\n|\r|’|‘)/gm, " ")
      .split(" ")
      .join("_")
  );
}

/**
 * Helper function to change single quote
 * @param text The text to process
 * @returns {string} title with another single quote
 */
export function replaceTextSingleQuote(text: string): string {
  return text.replaceAll("'", "’");
}

/**
 * Helper function to get the blurb from article data object
 * @param article
 * @returns {string} storyContentBlurb || standfirst || firstParagraph
 */
export const getArticleBlurb = (article: ArticleDataObject): string => {
  const storyContentBlurb = article.storyContent?.blurb;
  const articleElement: Element[] = article.elements || [];
  const firstParagraph = getFirstParagraph(articleElement);
  const standFirst = getStandfirstValue(articleElement);

  const hasLifestyleSection =
    article.sections?.some(
      ({ uniqueName }) =>
        uniqueName === "lifestyle" || uniqueName === "lifestyle_bt-luxe" // Hardcode it or get if from variable
    ) || false;

  if (hasLifestyleSection && standFirst)
    return truncate(standFirst, TRUNCATE_LENGTH);

  return truncate(storyContentBlurb || firstParagraph, TRUNCATE_LENGTH);
};

/**
 * Helper function to get the first paragraph from article elements.
 * @param elements
 * @returns {string} firstParagraphValue
 */
export const getFirstParagraph = (elements: Element[]): string => {
  if (!elements) return "";

  //Search for first paragraph
  const foundElement = elements.find(
    (element) =>
      element.type === "paragraph" &&
      element.fields.some((field) => field.name === "paragraph")
  );
  //Get the first paragraph value
  const firstParagraph = foundElement?.fields.find(
    (field) => field.name === "paragraph"
  )?.value;

  return firstParagraph ?? "";
};

/**
 * Helper function to get the standfirst value from article elements.
 * @param elements Article elements.
 * @returns {string|undefined}
 */
export const getStandfirstValue = (elements: Element[]): string | undefined => {
  if (!elements) return;

  // Search for standfirst element
  const standfirstElement = elements.find(
    (element) =>
      element.type === Type.Standfirst &&
      element.fields.some((field) => field.name === Name.Standfirst)
  );

  // Return if there is no standfirstElement.
  if (!standfirstElement) return;

  // Get the standfirst field value.
  const standFirstValue = getFieldValue(
    standfirstElement.fields,
    Name.Standfirst
  );

  // Return if standFirstValue is not a string.
  if (!(typeof standFirstValue === "string")) return;

  // Return standFirstValue.
  return standFirstValue;
};

/**
 * Helper function to trim text and add ellipsis if trimmed.
 * @param text The text to be trimmed.
 * @param length The number of words the text will be trimmed to.
 * @returns trimmedText || text
 */
export function truncate(text: string, length: number) {
  if (!text) return "";
  const total_words_array = text.trim().split(" ");
  const truncated_words_array = text.trim().split(" ", length);
  return total_words_array.length > length
    ? truncated_words_array.join(" ") + "..."
    : text;
}

/**
 * MySPH authentication modal functions
 */

export const mySPHOpenLogin = () => {
  if (!window._mySPHObj) return;
  window._mySPHObj.openLogin();
};

export const mySPHOpenLogout = () => {
  if (!window._mySPHObj) return;
  window._mySPHObj.openLogout();
};

export const mySPHOpenSignUp = () => {
  if (!window._mySPHObj) return;
  window._mySPHObj.openSignUp();
};

export const mySPHOpenUpdatePassword = () => {
  if (!window._mySPHObj) return;
  window._mySPHObj.openUpdatePassword(); // This method doesn't work
};

export const mySPHOpenResendVerificationMail = () => {
  if (!window._mySPHObj) return;
  window._mySPHObj.openResendVerificationMail();
};

/**
 * Helper function to format dates displayed in articles
 * @param dateValue (optional) The value of the date to be formatted. Returns empty string if falsey
 * @param dateFormat (optional) The Day.js format to display the date in
 * @returns string
 */
export const getFormattedTime = (dateValue?: string, dateFormat?: string) => {
  const dayjsTime = dayjs(dateValue).tz(dayjsSingaporeTimezone);

  if (!dayjsTime.isValid()) return "";
  if (dateFormat) return dayjsTime.format(dateFormat);

  return dayjsTime.format(defaultTimestampFormat);
};

/**
 * Helper function to format dates displayed in articles
 * @param dateValue The value of the date to compare the current time with
 * @returns string
 */
export const getTimeSince = (dateValue: string) => {
  const editedDateTime = dayjs(dateValue).tz(dayjsSingaporeTimezone);

  return editedDateTime.fromNow();
};

/**
 * Helper function to get the redirection url from the url by checking
 * if the url starts with a slash or not.
 * @param url  The url to be formatted
 */
export const getFormattedRedirectionUrl = (url?: string) => {
  if (url?.startsWith("/") || url?.startsWith("http")) {
    return url;
  }

  return `/${url}`;
};

/* Helper function to get the kicker from article data object.
 * @param article The article data object.
 * @returns {string}
 */
export const getKickerFromArticleDataObject = (
  article: ArticleDataObject
): string => {
  if (article === undefined || article === null) return "";

  return (
    article.elements?.find((element) => element.type === "kicker")?.fields?.[0]
      ?.value || ""
  );
};

/**
 * Helper function to get the mime type icon from file path or file name.
 * @param filename The filename.
 * @returns {string}
 */
export const getMimeTypeIcon = (filename: string): string => {
  const extension = filename.split(".").pop()?.toLowerCase();

  switch (extension) {
    case "pdf":
      return pdfIcon;
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
    case "bmp":
    case "svg":
      return imageIcon;
    default:
      return "";
  }
};

/**
 * Helper function to get the stock code from article data object.
 * @param article The article data object.
 * @returns {Array}
 */
export const getStockFromArticleDataObject = (
  article: ArticleDataObject
): string[] => {
  if (!article) return [];

  const stockPicker =
    article.elements
      ?.filter((element) => element.type === "stock_picker")
      .map((element) => element.fields?.[0]?.value || "") || [];

  const stockCodes = article?.storyContent?.stockCode?.split(",") || [];

  return uniq([...stockPicker, ...stockCodes]);
};

/**
 * Helper section to compare two list of Sections, if they are equal.
 * @param listA The first list of Section to compare.
 * @param listB The second list of Section to compare.
 * @returns {boolean}
 */
export const isSectionListEqual = (
  listA: Section[],
  listB: Section[]
): boolean =>
  JSON.stringify(listA.map((section) => section.uniqueName).sort()) ===
  JSON.stringify(listB.map((section) => section.uniqueName).sort());

/**
 * Helper function to convert a text to a slug.
 * @param text The text to convert.
 * @returns {string} Slug version of text.
 */
export const textToSlug = (text: string | undefined): string => {
  if (typeof text === "undefined") {
    return "";
  }

  return kebabCase(
    text
      .toLowerCase()
      .replace(/[\W_]+/g, "-")
      .replace(/-+/g, "-")
  );
};

/**
 * Helper function to convert a slug to a text.
 * @param slug The slug to convert
 * @returns {string} Text version of slug.
 */
export const slugToText = (slug: string): string => slug.replaceAll("-", " ");

/**
 * Helper function to check if URL is a URL from the specified from parameter.
 * @param url URL to check.
 * @returns {boolean}
 */
export const isThisUrlFrom = (url: string, from: string): boolean => {
  const domain = new URL(url).hostname;
  return domain.includes(from);
};

export const getPodcastStyle = (size: PodcastSize) => {
  if (size === "wide-image") return { style: "cover" };
  if (size === "wide-simple") return { style: "artwork" };
  if (size === "square") return { style: "cover", size: "square" };
  return { style: "cover" };
};

/**
 * Helper function to get the field value from fields.
 * @param fields The fields that contains array of field.
 * @param fieldName The name to pull out the data from
 * @returns {(string|boolean|undefined)}
 */
// @TODO Change code to use this function instead of manually getting field value.
export const getFieldValue = (
  fields: Field[],
  fieldName: Name
): string | boolean | undefined => {
  const field = fields.filter((field) => field.name === fieldName)?.[0];

  return field?.value || field?.booleanValue;
};

/**
 * Helper function to get the List tag.
 * @param type Type of list.
 * @returns {string}
 */
export const getListTag = (type: string): React.ElementType => {
  if (type === "list_bulleted") return "ul";
  if (type === "list_numbered") return "ol";

  return "ul";
};

/**
 * Helper function to get the image caption and credit from media summary.
 */
export const getImageCaptionCreditFromSummary = (
  summary: KeyValuePair[] | undefined
): string => {
  if (!summary) return "";

  const caption = summary.filter((field) => field.key === "caption")?.[0]
    ?.value;
  const credit = summary.filter((field) => field.key === "credit")?.[0]?.value;

  return `${caption} ${credit}`;
};

/**
 * Helper function to get the dimensions needed for filter. Will be adjusted depends
 * if image supplied is a landscape or portrait image.
 */
export const getDimensionsForFilter = (
  metadata: KeyValuePair[] | undefined,
  imageStyle: Pick<CaaSImageFilters, "w" | "h">
) => {
  if (!metadata) return {};

  const width = metadata.filter((field) => field.key === "width")?.[0]?.value;
  const height = metadata.filter((field) => field.key === "height")?.[0]?.value;

  if (typeof width === "undefined") return { h: imageStyle.h };
  if (typeof height === "undefined") return { w: imageStyle.w };

  if (parseInt(width) < parseInt(height)) return { h: imageStyle.h };
  return { w: imageStyle.w };
};

/**
 * Helper function to get Default image for category pages.
 * @param sectionUniqueName The unique name of category.
 * @returns {string}
 */
export const getDefaultImage = (sectionUniqueName: string) => {
  switch (sectionUniqueName) {
    case "consumer-healthcare":
      return consumerHealthDefaultImage;
    case "property":
      return propertyDefaultImage;
    case "international_asean":
      return aseanBusinessDefaultImage;
    case "international_global":
      return globalEnterpriseDefaultImage;
    case "startups-tech_startups":
      return garageDefaultImage;
    case "singapore_smes":
      return sgsmeDefaultImage;
    case "companies-markets_banking-finance":
      return companiesMarketsBankingFinanceDefaultImage;
    case "companies-markets_reits-property":
      return companiesMarketsReitsPropertyDefaultImage;
    case "companies-markets_energy-commodities":
      return companiesMarketsEnergyCommoditiesDefaultImage;
    case "companies-markets_telcos-media-tech":
      return companiesMarketsTelcosMediaTechDefaultImage;
    case "companies-markets_transport-logistics":
      return companiesMarketsTransportLogisticsDefaultImage;
    case "companies-markets_capital-markets-currencies":
      return companiesMarketsCapitalMarketsCurrenciesDefaultImage;
    case "startups-tech":
      return startupsTechDefaultImage;
    case "startups-tech_technology":
      return startupsTechTechnologyDefaultImage;
    case "opinion-features":
      return opinionFeaturesDefaultImage;
    case "opinion-features_columns":
      return opinionFeaturesColumnsDefaultImage;
    case "opinion-features_features":
      return opinionFeaturesFeaturesDefaultImage;
    case "singapore":
      return singaporeDefaultImage;
    case "singapore_economy-policy":
      return singaporeEconomyPolicyDefaultImage;
    case "international":
      return internationalDefaultImage;
    case "wealth_wealth-investing":
      return wealthWealthInvestingDefaultImage;
    case "wealth_personal-finance":
      return wealthPersonalFinanceDefaultImage;
    case "wealth_crypto-alternative-assets":
      return wealthCryptoAlternativeAssetsDefaultImage;
    case "working-life":
      return workingLifeDefaultImaage;
    case "companies-markets":
      return companiesMarketsDefaultImage;
    case "lifestyle_bt-luxe":
      return bTLuxeDefaultImage;
    case "lifestyle":
      return lifestyleDefaultImage;
    case "podcasts":
      return podcastsDefaultImage;
    default:
      return companiesMarketsDefaultImage;
  }
};

/**
 * Helper function to merge two list of arrays.
 * @param listA The first list of array.
 * @param listB The second list of array.
 * @returns {Array}
 */
export const mergeArrays = (arr1: Tag[], arr2: Tag[]) => {
  const mergedMap = new Map();

  // First, add objects from arr1 to the mergedMap
  for (const obj of arr1) {
    mergedMap.set(obj.name.toLowerCase(), obj);
  }

  // Then, add objects from arr2 to the mergedMap, overwriting duplicates
  for (const obj of arr2) {
    mergedMap.set(obj.name.toLowerCase(), obj);
  }

  // Convert the Map back to an array
  return Array.from(mergedMap.values());
};

/**
 * Helper function to get the mapped section name, that has different name than CUECMS.
 * @param sectionUniqueName The sectionUniqueName.
 * @param sectionName The sectionName.
 * @returns {string}
 */
export const getMappedSectionName = (
  sectionUniqueName: string,
  sectionName: string
): string => {
  switch (sectionUniqueName) {
    case "international_asean":
      return "ASEAN Business";

    case "international_global":
      return "Global Enterprise";

    case "singapore_smes":
      return "SGSME";

    default:
      return sectionName;
  }
};

export const scrollToNewsletterSection = (
  newsletter: string,
  weeklyNewsletterRef: React.RefObject<HTMLDivElement>
) => {
  if (newsletter === NewsletterType.PremiumNewsletter) {
    window.scrollTo({ top: 0, behavior: "smooth" });
  } else if (
    newsletter === NewsletterType.DailyNewsletters &&
    weeklyNewsletterRef.current
  ) {
    const yOffset = 450; // Adjust the offset as needed
    window.scrollTo({
      top: weeklyNewsletterRef.current.offsetTop - yOffset,
      behavior: "smooth",
    });
  } else if (
    newsletter === NewsletterType.WeeklyNewsletter &&
    weeklyNewsletterRef.current
  ) {
    const yOffset = 50; // Adjust the offset as needed
    window.scrollTo({
      top: weeklyNewsletterRef.current.offsetTop - yOffset,
      behavior: "smooth",
    });
  }
};

/**
 * Helper function to check if the path supplied is a vertical path.
 * @param curentPath The current path.
 * @returns {boolean}
 */
export const isPathAVerticalPath = (curentPath: string): boolean =>
  routesVerticals.includes(curentPath);

/**
 * Any changes done after 5 seconds of previous published is considered an update.
 * On first time publish of articles, edited and updated time in CUE is not the same,
 * there is always a difference of 2-3 seconds.
 * @param publishedTime AKA updated from CUE graphql
 * @param updatedTime AKA edited from CUE graphql
 *
 * @return {boolean}
 */
export const isArticleUpdated = (
  publishedTime: string,
  updatedTime: string
): boolean => {
  if (isEmpty(publishedTime) || isEmpty(updatedTime)) return false;

  const thresholdInSeconds = 3;
  return (
    dayjs(updatedTime).diff(dayjs(publishedTime), "seconds") >
    thresholdInSeconds
  );
};

type GaEventObject = {
  event: string;
  eventCategory: string;
  eventAction: string;
  eventLabel: string;
  nonInteraction: boolean;
  abVariant?: string;
};

export const gaEventTracker = (
  eventCategory: string,
  eventAction: string,
  eventLabel: string,
  nonInteraction = false,
  variantVal = ""
) => {
  const event_arr: GaEventObject = {
    event: "custom_event",
    eventCategory: eventCategory,
    eventAction: eventAction,
    eventLabel: eventLabel,
    nonInteraction: nonInteraction,
  };

  if (variantVal !== "") {
    event_arr.abVariant = variantVal;
  }

  window.dataLayer = window.dataLayer || [];
  (window.dataLayer as GaEventObject[]).push(event_arr);
};

export const myBTDataTracker = (tags: Tag[]) => {
  if (typeof window._data !== "undefined") {
    const tags_count = tags?.length ?? 0;
    window._data.product_flag = "mybt";
    tags_count > 0
      ? (window._data.user_keyword = tags?.map((t) => t.name).join("|"))
      : (window._data.user_keyword = "");
  }
};

export const myBTLoginTracker = (logged_user: boolean, label: string) => {
  const href = window.location.href;
  const eventLabel = logged_user ? `${label} - ${href}` : label;
  gaEventTracker("mybt", "click", eventLabel);
};

export const getArticleJSONLD = (article: ArticleDataObject): object => {
  const { urlPath, title, media, updated, edited, authors } = article;
  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "NewsArticle",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": `${LIVE_DOMAIN}${urlPath}`,
      datePublished: updated,
      dateModified: edited,
    },
    headline: replaceTextSingleQuote(title),
    image: media?.[0]?.content?.fields?.["original-caas"]?.url
      ? [media[0].content.fields["original-caas"].url]
      : "",
    publisher: {
      "@type": "Organization",
      name: "The Business Times",
      logo: {
        "@type": "ImageObject",
        url: `${LIVE_DOMAIN}${jsonLdLogo}`,
      },
    },
    author: authors?.map(({ name }) => ({
      "@type": "Person",
      name,
      url: `${LIVE_DOMAIN}/authors/${textToSlug(name)}`,
    })),
    description: getArticleBlurb(article),
  };

  return jsonLd;
};

/**
 * Helper function to get the author JSONLD.
 * @param param The profile content.
 * @returns {object}
 */
export const getAuthorJSONLD = ({
  fields: { name, bio_raw, twitterHandle },
  urlPath,
  headshotImage,
}: ProfileContent): object => {
  const dp = headshotImage?.[0]?.content.fields["square_480-caas"]?.url;
  const path = `${LIVE_DOMAIN}${urlPath}`;

  const jsonLd = {
    "@context": "https://schema.org",
    "@type": "Person",
    name: name,
    url: path,
    ...(typeof dp !== "undefined" ? { image: dp } : {}),
    jobTitle: "Journalist",
    ...(!isEmpty(twitterHandle)
      ? { sameAs: [`https://www.twitter.com/${twitterHandle}`] }
      : {}),
    worksFor: { "@type": "Organization", name: "The Business Times" },
    description: trim(bio_raw),
    author: { "@type": "Person", name: name },
    mainEntityOfPage: { "@type": "WebPage", "@id": path },
  };

  return jsonLd;
};

/**
 * Helper function to get the video JSON-LD.
 * See https://www.semrush.com/blog/video-schema/ for more information.
 * @param video The brightcove video object.
 * @returns {object}
 */
export const getVideoJSONLD = (video: BrightcoveVideo): object => {
  const { name, description, published_at, images, duration, id } = video;
  const domain = getEnvironmentDomain(ENVIRONMENT);

  const jsonLd = {
    "@context": "http://schema.org",
    "@type": "VideoObject",
    name: name,
    description: description,
    thumbnailUrl: [images.poster?.src],
    uploadDate: dayjs(published_at).format("YYYY-MM-DDTHH:mm:ssZ[Z]"),
    duration: dayjs.duration(duration).toISOString(),
    embedUrl: `${domain}/videos/${textToSlug(name)}/${id}`,
  };

  return jsonLd;
};

/**
 * Helper function to parse the story thread value from CUE.
 * @param storyThread Story thread field CUE value.
 * @returns {StoryThread|undefined}
 */
export const parseStoryThread = (
  storyThread?: string
): StoryThread | undefined => {
  if (typeof storyThread === "undefined") return;

  if (isEmpty(storyThread)) return;

  try {
    return JSON.parse(storyThread)?.[0];
  } catch (error) {
    return;
  }
};

/**
 * Helper function to append parameters to a URL.
 * @param url The URL you want to append parameters to.
 * @param parameters The list of parameters to append.
 * @returns {URL}
 */
export const addParamsToURL = (
  url: URL,
  parameters: { label: string; value: string }[]
): URL => {
  parameters.forEach(({ label, value }) => {
    if (isEmpty(value)) return;

    url.searchParams.set(label, value);
  });
  return url;
};

/**
 * Helper function to get the environment domain.
 * @param environment The current environment.
 * @returns {string}
 */
export const getEnvironmentDomain = (environment: string): string => {
  switch (environment) {
    case "local":
      return "http://localhost:3000";

    case "development-env":
      return "https://web2-dev.businesstimes.com.sg";

    case "staging-env":
      return "https://uat.businesstimes.com.sg";

    case "production-env":
    default:
      return "https://www.businesstimes.com.sg";
  }
};

export function logStart() {
  return performance.now();
}

export function logEnd(
  apiName: string,
  timeStart: number,
  apiEndpoint?: string,
  query?: unknown,
  traceId?: string | null
) {
  const timeEnd = performance.now();
  // eslint-disable-next-line no-console
  console.log({
    apiName: apiName,
    time: timeEnd - timeStart,
    apiEndpoint: apiEndpoint || "",
    query: JSON.stringify(query || {}),
    traceId: traceId || "",
  });
}

/**
 * Helper function to get the error message based on the status code.
 * @param statusCode The status code.
 * @returns {string}
 */
export const getStatusCodeErrorMessage = (
  statusCode?: HttpStatusCode
): string => {
  switch (statusCode) {
    case HttpStatusCode.BadRequest:
      return "Bad request. Please check your request and try again.";

    case HttpStatusCode.NotFound:
      return "The requested page could not be found.";

    case HttpStatusCode.InternalServerError:
      return "Internal Server Error. Please try again later.";

    case HttpStatusCode.ServiceUnavailable:
      return "Service Unavailable. Please try again later.";

    case HttpStatusCode.MovedPermanently:
      return "This page has a redirect and has been moved permanently.";

    default:
      return "An error occurred.";
  }
};

/**
 * Helper functions to merge classnames via tailwindMerge.
 * @param inputs
 * @returns
 */
export const cn = (...inputs: classNames.ArgumentArray) => {
  return twMerge(classNames(inputs));
};

/**
 * This function helps to check if article belongs to Branded Content kicker.
 * @param {Element[]} elements Article element.
 * @returns {boolean} A boolean value indicating whether Branded Content article.
 */
export const checkIsHubArticle = (elements: Element[]) => {
  return elements.some((element) => {
    return element.fields.some((field) => {
      return (
        field.value?.toUpperCase().replace(/\s/g, "") ===
        KickerEnums.BRANDED_CONTENT
      );
    });
  });
};

/**
 * Helper function to check if article is a Big Money article.
 * @param tags The tags of the article.
 * @returns {boolean}
 */
export const checkIsBigMoneyArticle = (tags: Tag[]): boolean => {
  return tags.some((tag) => {
    return BT_NEWSLETTERS.find(
      (newsletter) => newsletter.type === NEWSLETTER_TYPE.BigMoney
    )?.tags?.some((bigMoneyTag) => bigMoneyTag.uri === tag.uri);
  });
};
